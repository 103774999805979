/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLandworth = /* GraphQL */ `
  query GetLandworth($body: LandworthInput!) {
    getLandworth(body: $body)
  }
`;
export const getLocationHistoryPrice = /* GraphQL */ `
  query GetLocationHistoryPrice($body: LocationHistoryPriceInput!) {
    getLocationHistoryPrice(body: $body)
  }
`;
export const getLocationHistorySales = /* GraphQL */ `
  query GetLocationHistorySales($body: LocationHistorySalesInput!) {
    getLocationHistorySales(body: $body)
  }
`;
export const getPpsfChecker = /* GraphQL */ `
  query GetPpsfChecker($body: PpsfCheckerInput!) {
    getPpsfChecker(body: $body)
  }
`;
export const osAddressAutocomplete = /* GraphQL */ `
  query OsAddressAutocomplete($searchText: String!) {
    osAddressAutocomplete(searchText: $searchText)
  }
`;
export const getSearchData = /* GraphQL */ `
  query GetSearchData($searchID: String!) {
    getSearchData(searchID: $searchID) {
      searchID
      searchName
      searchCustomName
      searchLocationName
      dateRun
      propertyIDList
      searchSpec
      zooplaSearchComplete
      landregSearchComplete
      rmSearchComplete
      planningSearchComplete
      newBuildSearchesComplete
      comparableProperties
      isFavourite
      searchSortOrder
      showTodayPrice
      parentValuationId
      __typename
    }
  }
`;
export const getUserSearchHistory = /* GraphQL */ `
  query GetUserSearchHistory($filter: String) {
    getUserSearchHistory(filter: $filter) {
      searches {
        searchID
        searchName
        searchCustomName
        dateRun
        sourceTypes
        totalProperties
        totalComps
        isFavourite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSearchFavourites = /* GraphQL */ `
  query GetUserSearchFavourites($filter: String) {
    getUserSearchFavourites(filter: $filter) {
      searches {
        searchID
        searchName
        searchCustomName
        dateRun
        sourceTypes
        totalProperties
        totalComps
        isFavourite
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getValuationData = /* GraphQL */ `
  query GetValuationData($valuationId: String!) {
    getValuationData(valuationId: $valuationId) {
      valuationId
      valuationName
      valuationDate
      valuationPropertyId
      valuationPrice
      valuationPriceSource
      soldSearchId
      forsaleSearchId
      rentalSearchId
      planningSearchId
      newBuildSearchId
      oldSoldSearchId
      oldForsaleSearchId
      oldRentalSearchId
      oldPlanningSearchId
      oldNewBuildSearchId
      __typename
    }
  }
`;
export const getUserValuationHistory = /* GraphQL */ `
  query GetUserValuationHistory($filter: String) {
    getUserValuationHistory(filter: $filter) {
      valuations {
        valuationId
        valuationName
        valuationDate
        valuationPropertyId
        valuationPrice
        valuationPriceSource
        valuationPropertyType
        valuationPropertyBeds
        valuationPropertySqft
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserValuationHistoryWithPropertyData = /* GraphQL */ `
  query GetUserValuationHistoryWithPropertyData($filter: String) {
    getUserValuationHistoryWithPropertyData(filter: $filter) {
      valuations {
        valuationId
        valuationName
        valuationDate
        valuationPropertyId
        valuationPrice
        valuationPriceSource
        valuationPropertyType
        valuationPropertyBeds
        valuationPropertySqft
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const propertyData = /* GraphQL */ `
  query PropertyData($ids: [ID]) {
    propertyData(ids: $ids) {
      propertyID
      address
      addressLong
      lat
      lng
      price
      propertySource
      propertyType
      propertyTypeParent
      landworth
      lwsd
      lwTotalWeight
      latestLandworth
      latestLwsd
      latestLwTotalWeight
      lastSalePrice
      lastSaleDate
      lastSaleLandworth
      lastSaleLwTotalWeight
      lastSaleLwCachedDate
      sqft
      sqftSource
      size
      sizeUnit
      epcSqm
      floorplanURL
      lwCachedDate
      latestLwCachedDate
      zooplaCachedDate
      sqftCachedDate
      beds
      baths
      floors
      thumbnailURL
      dateFirstListed
      priceFirstListed
      dateUpdated
      rmAutoEmailReasonType
      priceChange
      listingURL
      listingStatus
      postcodeDistrict
      newbuild
      heatedRooms
      floorLevel
      dateSold
      dateRun
      postcode
      tenure
      lrSoldPriceHistoryURL
      lrSoldPriceHistory
      zHistoryURL
      zHistoryLinks
      zActiveLinks
      zPriceEstimate
      zBeds
      zBaths
      zReceptions
      zHistoryTags
      zHistoryPrimaryPictureUrl
      zHistoryTotalImages
      zHistoryImages
      zHistoryFloorplanURL
      rmHistoryURL
      rmHistoryCachedDate
      rmPropertyType
      rmBeds
      rmBaths
      rmTotalImages
      rmHistoryLinks
      rmListingPrimaryPictureURL
      rmHistoryFloorplanURL
      rmHistoryTags
      rmHistoryImages
      starred
      hidden
      isSharedOwnership
      priceModifier
      tags
      photoCount
      agentLogo
      agentName
      agentPhone
      hmlrid
      epcCertificateDate
      epcConstructionAgeBand
      lmk_key
      inspection_date
      currentEnergyRating
      currentEnergyScore
      potentialEnergyRating
      potentialEnergyScore
      uprn
      uprnSource
      epcTenure
      local_authority_id
      local_authority_name
      appSize
      appState
      appType
      firstListed
      lastChanged
      pageURL
      planningAppID
      numDocuments
      planningDocsURL
      numDwellings
      parish
      planningStatus
      planningDecision
      planningDescription
      __typename
    }
  }
`;
export const propertyDataByUser = /* GraphQL */ `
  query PropertyDataByUser($ids: [ID]) {
    propertyDataByUser(ids: $ids) {
      propertyID
      address
      addressLong
      lat
      lng
      price
      propertySource
      propertyType
      propertyTypeParent
      landworth
      lwsd
      lwTotalWeight
      latestLandworth
      latestLwsd
      latestLwTotalWeight
      lastSalePrice
      lastSaleDate
      lastSaleLandworth
      lastSaleLwTotalWeight
      lastSaleLwCachedDate
      sqft
      sqftSource
      size
      sizeUnit
      epcSqm
      floorplanURL
      lwCachedDate
      latestLwCachedDate
      zooplaCachedDate
      sqftCachedDate
      beds
      baths
      floors
      thumbnailURL
      dateFirstListed
      priceFirstListed
      dateUpdated
      rmAutoEmailReasonType
      priceChange
      listingURL
      listingStatus
      postcodeDistrict
      newbuild
      heatedRooms
      floorLevel
      dateSold
      dateRun
      postcode
      tenure
      lrSoldPriceHistoryURL
      lrSoldPriceHistory
      zHistoryURL
      zHistoryLinks
      zActiveLinks
      zPriceEstimate
      zBeds
      zBaths
      zReceptions
      zHistoryTags
      zHistoryPrimaryPictureUrl
      zHistoryTotalImages
      zHistoryImages
      zHistoryFloorplanURL
      rmHistoryURL
      rmHistoryCachedDate
      rmPropertyType
      rmBeds
      rmBaths
      rmTotalImages
      rmHistoryLinks
      rmListingPrimaryPictureURL
      rmHistoryFloorplanURL
      rmHistoryTags
      rmHistoryImages
      starred
      hidden
      isSharedOwnership
      priceModifier
      tags
      photoCount
      agentLogo
      agentName
      agentPhone
      hmlrid
      epcCertificateDate
      epcConstructionAgeBand
      lmk_key
      inspection_date
      currentEnergyRating
      currentEnergyScore
      potentialEnergyRating
      potentialEnergyScore
      uprn
      uprnSource
      epcTenure
      local_authority_id
      local_authority_name
      appSize
      appState
      appType
      firstListed
      lastChanged
      pageURL
      planningAppID
      numDocuments
      planningDocsURL
      numDwellings
      parish
      planningStatus
      planningDecision
      planningDescription
      __typename
    }
  }
`;
export const soldPropertiesAutocomplete = /* GraphQL */ `
  query SoldPropertiesAutocomplete($body: SoldPropertiesInput!) {
    soldPropertiesAutocomplete(body: $body)
  }
`;
export const epcPropertiesAutocomplete = /* GraphQL */ `
  query EpcPropertiesAutocomplete($body: SoldPropertiesInput!) {
    epcPropertiesAutocomplete(body: $body)
  }
`;
export const getUPRNDetails = /* GraphQL */ `
  query GetUPRNDetails(
    $uprn: String
    $postcode: String
    $matchAddress: String
  ) {
    getUPRNDetails(
      uprn: $uprn
      postcode: $postcode
      matchAddress: $matchAddress
    )
  }
`;
export const findFullAddress = /* GraphQL */ `
  query FindFullAddress($postcode: String, $price: String) {
    findFullAddress(postcode: $postcode, price: $price)
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($userID: String!) {
    getUserData(userID: $userID) {
      userID
      userType
      starredProperties
      hiddenProperties
      manualSizeData
      __typename
    }
  }
`;
export const getCredits = /* GraphQL */ `
  query GetCredits($userId: String, $orgId: String) {
    getCredits(userId: $userId, orgId: $orgId)
  }
`;
export const getAuditLog = /* GraphQL */ `
  query GetAuditLog($userId: String, $orgId: String) {
    getAuditLog(userId: $userId, orgId: $orgId)
  }
`;
export const getStripeCheckout = /* GraphQL */ `
  query GetStripeCheckout(
    $purchaseType: String
    $price: String
    $successUrl: String
    $cancelUrl: String
  ) {
    getStripeCheckout(
      purchaseType: $purchaseType
      price: $price
      successUrl: $successUrl
      cancelUrl: $cancelUrl
    )
  }
`;
export const getStripePortal = /* GraphQL */ `
  query GetStripePortal($returnUrl: String) {
    getStripePortal(returnUrl: $returnUrl)
  }
`;
