import blockedDomains from "./blocked_domains_23jan25.json";

const customBlockList = [
  "yoli.cam",
  "couldmail.com",
  "qmail.my",
  "vigoneo.com",
  "qmail.edu.pl",
];

export const isValidDomain = (emailDomain) => {
  if (
    blockedDomains.includes(emailDomain) ||
    customBlockList.includes(emailDomain)
  ) {
    return false;
  } else {
    return true;
  }
};
