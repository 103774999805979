import { useHistory } from "react-router";
import Button from "@material-ui/core/Button";
import SearchStatusOneLine from "components/SearchStatusOneLine";
import { writeStorage } from "@rehooks/local-storage";
// import ExpandSearch from "./ExpandSearch";
import ExpandSearchDropdown from "./ExpandSearchDropdown";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import deviceSizes from "../../styleConstants";
import { useDispatch } from "react-redux";

import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { undoExpandSearch } from "graphql/functions";
import { setValuationSearchesRedux } from "data/slices/valuationSlice";

const ValuationReportSearchSummary = (props) => {
  const {
    valuationData,
    properties,
    searchType,
    searchFinished,
    cacheFinished,
    search,
  } = props;
  const isNarrow = useMediaQuery(`(max-width:${deviceSizes.n780}px)`);

  const history = useHistory();
  const showTodayPrice = search.showTodayPrice;
  const dispatch = useDispatch();

  const searchId =
    searchType === "sold"
      ? valuationData.soldSearchId
      : searchType === "forsale"
      ? valuationData.forsaleSearchId
      : searchType === "rental"
      ? valuationData.rentalSearchId
      : searchType === "planning"
      ? valuationData.planningSearchId
      : valuationData.newBuildSearchId;

  const oldSearchId =
    searchType === "sold"
      ? valuationData.oldSoldSearchId
      : searchType === "forsale"
      ? valuationData.oldForsaleSearchId
      : searchType === "rental"
      ? valuationData.oldRentalSearchId
      : searchType === "planning"
      ? valuationData.oldPlanningSearchId
      : valuationData.oldNewBuildSearchId;

  const MapViewButton = () => (
    <Button
      variant="outlined"
      color="default"
      size="small"
      onClick={() => {
        writeStorage("search_view", isNarrow ? "full_map" : "split");
        history.push(`/search/${searchId}`);
      }}
      style={{ backgroundColor: "white" }}
      // disabled={searchType === "planning"}
    >
      <FontAwesomeIcon icon={regular("map")} />
      &nbsp;&nbsp;See Map
    </Button>
  );

  const UndoExpandSearchButton = (props) => {
    const { valuationId, searchType } = props;
    const undoExpandSearchTrigger = (valuationId, searchType) => {
      window.analytics.track("VR - Undo Expand Search", {
        valuationId,
        searchType,
      });
      const newSearchIds =
        searchType === "sold"
          ? { soldSearchId: valuationData.oldSoldSearchId }
          : searchType === "forsale"
          ? { forsaleSearchId: valuationData.oldForsaleSearchId }
          : searchType === "rental"
          ? { rentalSearchId: valuationData.oldRentalSearchId }
          : searchType === "planning"
          ? { planningSearchId: valuationData.oldPlanningSearchId }
          : { newBuildSearchId: valuationData.oldNewBuildSearchId };

      undoExpandSearch({ valuationId, searchType });
      dispatch(
        setValuationSearchesRedux({
          id: valuationId,
          ...newSearchIds,
        })
      );
    };

    return (
      <Button
        variant="outlined"
        color="default"
        size="small"
        onClick={() => {
          undoExpandSearchTrigger(valuationId, searchType);
        }}
        style={{ backgroundColor: "white" }}
      >
        Undo&nbsp;&nbsp;
        <FontAwesomeIcon icon={solid("undo")} />
      </Button>
    );
  };

  return (
    <>
      {properties.length > 0 ? (
        <div
          style={{
            padding: "4px 0",
            fontSize: "20px",
            justifyContent: "space-between",
          }}
          className="flex-center-center"
        >
          {isNarrow ? (
            <div
              className="flex-center-center"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <div className="flex-center-center">
                <SearchStatusOneLine
                  searchId={searchId}
                  properties={properties}
                  searchType={searchType}
                  valuationData={valuationData}
                  searchFinished={searchFinished}
                  cacheFinished={cacheFinished}
                  showTodayPrice={showTodayPrice}
                  isSmallWidth={true}
                />
              </div>

              <div
                className="flex-center-center"
                style={{
                  flexDirection: "row",
                  paddingTop: "12px",
                }}
              >
                <MapViewButton />
                &nbsp;&nbsp;
                <ExpandSearchDropdown
                  valuationId={valuationData?.valuationId}
                  searchId={searchId}
                  searchType={searchType}
                />
              </div>
            </div>
          ) : (
            <>
              <MapViewButton />
              <SearchStatusOneLine
                searchId={searchId}
                properties={properties}
                searchType={searchType}
                valuationData={valuationData}
                searchFinished={searchFinished}
                cacheFinished={cacheFinished}
                showTodayPrice={showTodayPrice}
                isSmallWidth={false}
              />

              <div class="flex-center-center">
                {!!oldSearchId && oldSearchId !== searchId && (
                  <>
                    <UndoExpandSearchButton
                      valuationId={valuationData?.valuationId}
                      searchType={searchType}
                    />
                    &nbsp;
                  </>
                )}
                <ExpandSearchDropdown
                  valuationId={valuationData?.valuationId}
                  searchId={searchId}
                  searchType={searchType}
                />
              </div>
            </>
          )}
        </div>
      ) : searchFinished && cacheFinished && properties.length === 0 ? (
        <>
          <br />
          <br />
          <div
            className="flex-center-center"
            style={{ flexDirection: "column" }}
          >
            <div>
              No{" "}
              {searchType === "planning"
                ? "Planning Applications"
                : "Properties"}{" "}
              Found
            </div>
            <br />
            <ExpandSearchDropdown
              valuationId={valuationData?.valuationId}
              searchId={searchId}
              searchType={searchType}
            />
          </div>
          <br />
          <br />
          <br />
        </>
      ) : (
        <>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="loader-small"></div>
          </div>
          <br />
          <br />
        </>
      )}
    </>
  );
};

export default ValuationReportSearchSummary;
