import React, { useCallback, useMemo, useState, useRef } from "react";
import { expandValuationSearch } from "graphql/functions";

import { useSelector, useDispatch } from "react-redux";
import { getSearchSelector, getValuationSelector } from "data/selectors";
import { setValuationSearchesRedux } from "data/slices/valuationSlice";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  capitaliseFirstLetter,
  decimalToUnicodeFraction,
} from "services/formatting";
import { convertLrPropertyTypeToFullText } from "services/propertyHelpers";
import { planningSearchRadiusSimplify } from "services/search";

const ExpandSearchDropdown = (props) => {
  const { valuationId, searchType, searchId } = props;
  const [isExpandingSearch, setIsExpandingSearch] = useState(false);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const valuation = useSelector((state) =>
    getValuationSelector(state, valuationId)
  );
  const search = useSelector((state) => getSearchSelector(state, searchId));

  const dispatch = useDispatch();

  const replaceSearchWithExpandedSearch = useCallback(
    async (searchType, newSearchSpec) => {
      setOpen(false);
      setIsExpandingSearch(true);
      const newSearchIds = await expandValuationSearch({
        valuationId,
        oldSearchId: searchId,
        oldSearchLocationName: search.locationName,
        searchType,
        newSearchSpec,
      });

      dispatch(
        setValuationSearchesRedux({
          id: valuation.valuationId,
          ...newSearchIds,
        })
      );
      setIsExpandingSearch(false);
    },
    [search]
  );

  const ExpandItem = ({ label, newSpecItem }) => {
    return (
      <MenuItem
        key={label}
        variant="contained"
        color="primary"
        size="small"
        dense
        onClick={() => {
          const newSpec = {
            ...search?.spec,
            ...newSpecItem,
          };
          window.analytics.track("Click Find More", {
            valuationId,
            label,
            newSpecItem,
          });
          replaceSearchWithExpandedSearch(searchType, newSpec);
        }}
      >
        {label}
      </MenuItem>
    );
  };

  const radiusLabel = useMemo(() => {
    // Display labels such as "1/4 mile -> 1/2 mile"
    return (
      <>
        <FontAwesomeIcon
          icon={solid("drafting-compass")}
          style={{ fontSize: "1.1em", minWidth: "20px", paddingRight: "8px" }}
        />
        {planningSearchRadiusSimplify(
          `${decimalToUnicodeFraction(search?.spec?.searchradius / 1609)} mile`
        )}
        {search?.spec?.searchRadius > 1609 ? `s` : ""}
        <FontAwesomeIcon
          icon={regular("angles-right")}
          style={{ padding: "0 5px" }}
        />
        {planningSearchRadiusSimplify(
          `${decimalToUnicodeFraction(
            (search?.spec?.searchradius * 2) / 1609
          )} mile`
        )}
        {search?.spec?.searchRadius > 1609 ? `s` : ""}
      </>
    );
  }, [search]);

  const timeLabel = useMemo(() => {
    return (
      <>
        <FontAwesomeIcon
          icon={solid("calendar-alt")}
          style={{ fontSize: "1.1em", minWidth: "20px", paddingRight: "8px" }}
        />
        {search?.spec?.sold_history_months / 12} year
        {search?.spec?.sold_history_months / 12 > 1 ? `s` : ""}
        <FontAwesomeIcon
          icon={regular("angles-right")}
          style={{ padding: "0 5px" }}
        />
        {(search?.spec?.sold_history_months * 2) / 12} years
      </>
    );
  }, [search]);

  const propertyTypeLabel = useMemo(() => {
    return (
      <>
        <FontAwesomeIcon
          icon={solid("city")}
          style={{ fontSize: "1.1em", minWidth: "20px", paddingRight: "8px" }}
        />
        {capitaliseFirstLetter(search?.spec?.property_type)}
        <FontAwesomeIcon
          icon={regular("angles-right")}
          style={{ padding: "0 5px" }}
        />
        All Types
      </>
    );
  }, [search]);

  const houseTypeLabel = useMemo(() => {
    return (
      <>
        <FontAwesomeIcon
          icon={solid("house")}
          style={{ fontSize: "1.1em", minWidth: "20px", paddingRight: "8px" }}
        />
        {convertLrPropertyTypeToFullText(
          search?.spec?.house_types && search?.spec?.house_types[0]
        )}
        <FontAwesomeIcon
          icon={regular("angles-right")}
          style={{ padding: "0 5px" }}
        />
        All Houses
      </>
    );
  }, [search]);

  const bedsTypeLabel = useMemo(() => {
    return (
      <>
        <FontAwesomeIcon
          icon={solid("bed")}
          style={{ fontSize: "1.1em", minWidth: "20px", paddingRight: "8px" }}
        />
        {search?.spec?.bedmin} bed
        {search?.spec?.bedmin === 1 ? `` : "s"}
        <FontAwesomeIcon
          icon={regular("angles-right")}
          style={{ padding: "0 5px" }}
        />
        Any Beds
      </>
    );
  }, [search]);

  return (
    <div>
      <ButtonGroup
        // variant={search?.propertyIds?.length < 10 ? "contained" : "outlined"}
        // color={search?.propertyIds?.length < 10 ? "primary" : "default"}
        variant="outlined"
        color="default"
        size="small"
        ref={anchorRef}
        aria-label="split button"
        style={{ backgroundColor: "white" }}
      >
        <Button onClick={handleToggle} disabled={isExpandingSearch}>
          <FontAwesomeIcon icon={regular("magnifying-glass")} />
          &nbsp;&nbsp;Expand
          {isExpandingSearch && (
            <>
              &nbsp;&nbsp;
              <div className="loader-tiny" />
            </>
          )}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "999" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <ExpandItem
                    label={radiusLabel}
                    newSpecItem={{
                      searchradius: search?.spec?.searchradius * 2,
                    }}
                  />
                  {search?.spec?.search_landreg && (
                    <ExpandItem
                      label={timeLabel}
                      newSpecItem={{
                        sold_history_months:
                          search?.spec?.sold_history_months * 2,
                      }}
                    />
                  )}
                  {(search?.spec?.property_type === "flat" ||
                    search?.spec?.property_type === "flats" ||
                    search?.spec?.property_type === "house" ||
                    search?.spec?.property_type === "houses") && (
                    <ExpandItem
                      label={propertyTypeLabel}
                      newSpecItem={{
                        property_type: "all",
                      }}
                    />
                  )}
                  {!!search?.spec?.search_landreg &&
                    JSON.stringify(search?.spec?.house_types) !==
                      JSON.stringify(["T", "S", "D"]) && (
                      <ExpandItem
                        label={houseTypeLabel}
                        newSpecItem={{
                          property_type: "houses",
                          house_types: ["T", "S", "D"],
                        }}
                      />
                    )}
                  {search?.spec?.pricemin !== 0 &&
                    search?.spec?.pricemax !== 0 && (
                      <ExpandItem
                        label={
                          <>
                            <FontAwesomeIcon icon={regular("pound-sign")} />
                            &nbsp;&nbsp;&nbsp;&nbsp;All Prices
                          </>
                        }
                        newSpecItem={{
                          pricemin: 0,
                          pricemax: 200000000,
                        }}
                      />
                    )}
                  {search?.spec?.bedmin !== 0 && search?.spec?.bedmax !== 50 && (
                    <ExpandItem
                      label={bedsTypeLabel}
                      newSpecItem={{
                        bedmin: 0,
                        bedmax: 50,
                      }}
                    />
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ExpandSearchDropdown;
